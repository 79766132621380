body, html {
    margin: 0;
    padding: 0;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    color: #333;
    font-weight: 400;
    background: #f3f3f6;
    font-size: 14px;
}
a {
    text-decoration: none;
}
a:focus, a:hover {
    color: #409eff;
}
.clearFix {
    zoom: 1;
}
.clearFix:after {
    content: '';
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
}
.text-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.text-overflow-2 {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.flex {
    display: flex;
}
.admin-content {
    width: 1200px;
    height: 100%;
    display: flex;
    .left-wrapper {
        width: 240px;
        background: #fff;
        border-radius: 4px;
        height: 100%;
        margin-right: 20px;
    }
    .main-wrapper {
        background: #fff;
        border-radius: 4px;
        height: 100%;
        width: 1%;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        box-sizing: border-box;
    }
}
.pages-center {
    margin-top: 10px;
    text-align: center;
}
.el-menu--horizontal>.el-menu-item.is-active{
    border-bottom: 2px solid #4154ea;
}
//start 总管理员总体布局 样式
.admin-container {
    height: 100%;
    //min-width: 1200px;
    .admin-wrapper {
        width: 1200px;
        margin: 20px auto;
        height: calc(100% - 100px);
    }
}
.admin-header {
    width: 100%;
    background: #fff;
    box-shadow: 0 0 5px 0 rgba(32, 32, 32, .11);
    .header {
        width: 1200px;
        height: 60px;
        /*line-height: 60px;*/
        margin: 0 auto;
        display: flex;
        .header-logo {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .nav-wrapper {
            width: 1%;
            flex: 1;
            margin-left: 20px;
            display: flex;
            justify-content: space-between;
            .el-menu-item {
                padding: 0;
                .nav-item {
                    padding: 0 20px;
                    display: block;
                }
            }
            .user-info {
                display: flex;
                align-items: center;
                .user-avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                        display: block;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .user-name {
                    max-width: 80px;
                    display: block;
                    cursor: pointer;
                    margin: 0 10px;
                    &:focus {
                        outline: none;
                    }
                }
                padding-left: 20px;
            }
        }
    }
}
.admin-top {
    display: flex;
    flex-wrap: wrap;
    padding:10px 20px;
    .top-left {
        width: 1%;
        flex: 1;
        .left-title {
            font-size:14px;
            line-height: 40px;
            font-weight: 500;
        }
    }
    .top-right {
        margin-left: 20px;
    }
}
.student-wrapper{
    height: calc(100% - 60px);
    width: 100%;
}

.top-right {
    margin-left: 20px;
    ::v-deep.el-button--primary {
        color: #FFF;
        background-color:#0824ce;
        border-color: #0824ce;
    }
    ::v-deep.el-button--primary:focus, .el-button--primary:hover {
        background: #495df1;
        border-color: #495df1;
        color: #FFF;
    }
}
//按钮公用
.btn-red-30 {
    display: inline-block;
    color: #fff !important;
    height: 30px;
    font-size: 12px;
    padding: 0 15px;
    line-height: 30px;
    background: #fc0014;
}
.btn-red-30:hover,
.btn-red-30:focus,
.btn-red-30.current {
    background: #dc0012;
    color: #fff;
}
.btn-green-30 {
    display: inline-block;
    color: #fff !important;
    height: 30px;
    font-size: 12px;
    padding: 0 15px;
    line-height: 30px;
    background: #20C997;
}
.btn-green-30:hover,
.btn-green-30:focus,
.btn-green-30.current {
    background: #20c998;
    color: #fff;
}
.btn-gray-30 {
    display: inline-block;
    color: #fff;
    height: 30px;
    font-size: 12px;
    padding: 0 15px;
    line-height: 30px;
    background: #999999;
    cursor: not-allowed;
}
.btn-air-32 {
    width: 120px;
    height: 32px;
    line-height: 30px;
    color: #333;
    border: 1px solid #20c997;
    text-align: center;
    border-radius: 16px;
    display: inline-block;
    cursor: pointer;
}
.btn-air-32.current,
.btn-air-32:focus,
.btn-air-32:hover {
    border-color: #FF0606;
    color: #FF0606;
}

.btn-grablue-32 {
  width: 100px;
  height: 32px;
  line-height: 32px;
  color: #fff;
  background: linear-gradient(to right, #2338E6, #4F61F1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  border: none;
  padding: 0;
}
.btn-grablue-32 i {
  margin-right: 2px;
}
.btn-grablue-32:hover,
.btn-grablue-32:focus,
.btn-grablue-32.current {
  background: linear-gradient(to right, #182cd4, #3c50f5);
  color: #fff;
}
//按钮公用
ul,li{
    padding: 0;
    margin: 0;
    list-style: none;

}

//学生端 图文创作 el-popover 弹窗样式修改
.el-popover.draftPopover {
    min-width: initial;
    padding: 0;
}
.el-popover.correctPopover {
    width: 360px;
    padding: 0;
}
.el-popover.promoteRank-popover {
    min-width: initial;
}
//.video-container {
//    .el-tabs__content{
//        max-height: 586px;
//    }
//}
.area-item{
    .area-name{
        max-width: 100px;
    }
}

// S 学生端面包屑
.breadcrumb-student {
    .el-breadcrumb__inner {
        color: #BCB9CB;
    }
    .el-breadcrumb__item:last-child .el-breadcrumb__inner,
    .el-breadcrumb__item:last-child .el-breadcrumb__inner a,
    .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
    .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
        color: #5F5C74;
        font-weight: normal;
    }
}
// E 学生端面包屑

// S 按钮样式覆盖
.blue-btn {
    color: #fff;
    background: #574DED;
    border-color: #574DED;
    &:focus,
    &:hover {
        color: #fff;
        border-color: #463bdc;
        background-color: #463bdc;
    }
}
.a-blue-btn {
    color: #fff;
    background: #1D35E3;
    border-color: #1D35E3;
    &:focus,
    &:hover {
        color: #fff;
        border-color: #495df1;
        background-color: #495df1;
    }
}
.link-blue {
    &.el-link {
        color: #1D33E1;
        &:hover {
            color: #5864e4;
        }
    }
}
.btn-orange {
    color: #fff;
    background: #FE4800;
    border-color: #FE4800;
    &:focus,
    &:hover {
        color: #fff;
        border-color: #FD815C;
        background-color: #FD815C;
    }
}
.btn-gray {
    color: #333;
    background: #F2F2F2;
    border-color: #F2F2F2;
    &:focus,
    &:hover {
        color: #333;
        border-color: #ddd;
        background-color: #ddd;
    }
}
// E 按钮样式覆盖

//创作中心面包屑
.interactive-breadcrumb {
    margin-bottom: 20px;
    ::v-deep .el-breadcrumb__item .el-breadcrumb__inner {
        color: #BCB9CB;
        font-weight: 400;
    }
    ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
        color: #5F5C74;
    }
}
//脚本创作列表按钮

.btn-green-script{
    color: #9ACC5B;
    font-size: 14px;
    &:focus,
    &:hover {
        color: #9ACC5C;
    }
}
.btn-blue-script{
    color:#1E33E4;
    font-size: 14px;
    &:focus,
    &:hover {
        color: #1b2dbf;
    }
}
.btn-red-script{
    color:#F90B32;
    font-size: 14px;
    &:focus,
    &:hover {
        color: #FB0338;
    }
}
//弹窗垂直居中
.centerDialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0!important;
}

//圆形选中的分页
.circle-page {
    text-align: center;

    .el-pager li {
        line-height: 26px;

        &.active {
            min-width: 24px;
            height: 24px;
            //min-width: unset;
            background: #564CED;
            border-radius: 12px;
            color: #ffffff;

            &:hover{
                color: #ffffff;
            }
        }

        &:hover {
            color: #564CED;
        }
    }
}

//S 学生端 自定义dialog
.customDialog {
    .el-dialog__header {
        padding: 20px 30px 10px;
    }
    .el-dialog__headerbtn {
        right: 30px;
    }
    .el-dialog__title {
        font-size: 16px;
    }
    .el-dialog__body {
        //padding: 20px 30px;
        padding: 0;
    }
}

.messageDialog {
    .el-dialog__header {
        display: none;
    }
    .el-dialog__body {
        padding: 0;
    }
}
//E 学生端 自定义dialog

// 数据看板选择样式
.data-spectaculars-select{
    .el-cascader-panel{
        .no-data{
            width: 250px;
            color: #fff;
            text-align: center;
            line-height: 1;
            margin-top: 10px;
        }
        border: none;
        display: flex;
        width: 100%;
        .el-cascader-menu{
            .is-vertical .el-scrollbar__thumb{
                background: #16D0FF;
            }
            .el-checkbox .is-checked .el-checkbox__inner{
                //border-radius: 50%;
                border-color: #09C1E1;
                background-color: #09C1E1;
                &::after{
                    border-color: #1D2075;
                }
            }
            .el-checkbox .is-indeterminate .el-checkbox__inner{
                border-color: #09C1E1;
                background-color: #09C1E1;
                &::after{
                    border-color: #1D2075;
                }
            }
            &:first-of-type{
                border: none;
                .el-cascader-menu__wrap{
                    height: 340px;
                    margin-bottom: 0!important;
                    .el-cascader-menu__list{
                        .el-icon-arrow-right{
                            display: none;
                        }
                        li{
                            &:nth-child(1){
                                margin-top: 0;
                            }
                            background: none;
                            margin-top: 10px;
                            &:hover{
                                background: none;
                            }
                        }
                        .el-cascader-node{
                            font-size: 12px;
                            color: #fff;
                            font-weight: 400;
                            text-align: center;
                            padding:0 14px 0 0;
                            span{
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            .el-cascader-node__label{
                                margin-left: 10px;
                                width: 236px;
                                padding: 0 10px;
                                display: inline-block;
                                box-sizing: border-box;
                                box-shadow:rgba(11, 234, 235,0.8) 0px 0px 8px inset;
                            }
                        }
                        .in-active-path{
                            background: none;
                            .el-cascader-node__label{
                                background: #09B5DA;
                            }
                        }
                    }
                }
            }
            &:last-of-type{
                display: block;
                margin-top: 6px;
                margin-left: 34px;
                width: 250px;
                .el-cascader-menu__wrap{
                    height: 332px;
                    margin-bottom: 0!important;
                    .el-cascader-menu__list{
                        width: 238px;
                        box-shadow:rgba(11, 234, 235,0.8) 0px 0px 8px inset;
                        .el-cascader-node{
                            .el-cascader-node__label{
                                color: #FFFFFF;
                                font-weight: 400;
                                font-size: 12px;
                            }
                            background: none;
                        }
                    }
                }
                .is-vertical{
                    right: 0px;
                }
            }
        }
    }
}
.category-tooltip {
    & * {
        box-sizing: border-box;
    }
    padding: 8px 5px !important;
    border: none !important;
    box-shadow: 3px 3px 5px #909399;
    .select-icon {
        .el-icon-success {
            color: #685EF3;
        }
        .null-circle {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 1px solid #909399;
        }
    }
    .popper__arrow {
        border-bottom-color: #eee !important;
    }
}
.no-data {
    background: url("../img/live/no_data.png") no-repeat;
    background-size: cover;
}
