
.left-wrapper {
    .class-list {
        height: 1%;
        flex: 1;

        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }

    ::v-deep .el-menu {
        background-color: transparent;
        border-right: none;
    }

    ::v-deep .el-col-12 {
        width: 100%;
    }

    ::v-deep .el-submenu__title {
        color: #685EF3;

        i {
            color: #685EF3;
            margin-right: 9px;
            font-size: 20px;
            text-align: center;
            vertical-align: middle;
        }

        &:hover {
            background-color: transparent;
        }
    }

    ::v-deep .el-menu-item {
        color: #fff;
    }

    ::v-deep .el-menu li.el-menu-item-group ul li.el-menu-item {
        background-color: #F1F0FE;
        border-left: 2px solid transparent;
        color: #fff;
    }

    ::v-deep .el-submenu .el-menu-item {
        padding-left: 44px;
    }

    ::v-deep .el-menu-item-group {
        background: #F1F0FE;
    }

    ::v-deep .el-menu-item-group>ul {
        padding-bottom: 14px;
    }

    ::v-deep .el-menu li.el-menu-item-group ul li.is-active {
        background-color: #9ACC5B;
        border-radius: 22px;
        /*border-left: 2px solid #20c997;*/
        color: #fff;
    }

    ::v-deep .el-menu li.el-menu-item-group ul li.is-active a{
        color: #fff;
    }

    /*::v-deep a:focus, a:hover {*/
    /*    color: #fff;*/
    /*}*/

    .nav-item {
        padding: 0 20px;
        display: block;
        color: #3A3567;
    }

    .work-title {
        height: 56px;
        line-height: 56px;
        position: relative;
        -webkit-box-sizing: border-box;
        white-space: nowrap;
        list-style: none;
        padding-left: 20px;
        font-size: 14px;
        cursor: pointer;
        transition: border-color .3s, background-color .3s, color .3s;
        box-sizing: border-box;
        color: #fff;
    }
}
.student-content{
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    .left-wrapper {
        z-index: 1;
        width: 221px;
        background-color: #fff;
        height: 100%;
        box-shadow: 0px 0px 17px 1px rgba(11, 12, 28, 0.09);
    }
    ::v-deep .right-wrapper{
        width: 1%;
        flex: 1;
        //padding: 20px;
        //overflow: auto;
        & > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        & > .el-scrollbar__wrap > .el-scrollbar__view {
            min-width: 1000px;
            height: 100%;
        }
        .right-wrapper-content {
            //min-width: 1000px;
            padding: 20px;
            position: relative;
            background: #F1F0FE;
            /*height: calc(100% - 40px);*/
        }
    }
    .main-wrapper {
        background: #fff;
        border-radius: 4px;
        height: 100%;
        width: 1%;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        box-sizing: border-box;
    }
}
